import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { IActionMessage } from '../interfaces/action-message.interface';

@Injectable()

export class MessageBusService {

    subject: BehaviorSubject<IActionMessage>;

    constructor() {
        this.subject = new BehaviorSubject<IActionMessage>({ action: 'no-ops' });
    }

    send(message: IActionMessage) {
        this.subject.next(message);
    }

    receive(): Observable<any> {
        return this.subject.asObservable();
    }

}
