
import { finalize } from 'rxjs/operators';
import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { IActionMessage } from '../../core/interfaces/action-message.interface';
import { MessageBusService } from '../../core/services/message-bus.service';
import { Subscription } from 'rxjs';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../core/services/constants';
import { ApplicationInfoVO } from '../vos/application-info.vo';
import { UserPhotosVO } from '../vos/user-photos.vo';
import { UrlParams } from '../classes/url-params.class';
import { UserDataVO } from '../vos/user-data.vo';
import { ConfigurationService } from '../../core/services/configuration.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserVO } from '../vos/discover-users.vo';
import { MessagingChannelVO } from '../vos/messaging-channel.vo';
import { MessagingTokenVO } from '../vos/messaging-token.vo';
import { UserService } from '../../core/services/user.service';

declare let analytics: any;
declare let amplitude: any;

@Injectable()

export class CommonFunctions {

    translationsLoaded: boolean;

    constructor(private messaging: MessageBusService, private user: UserService,
        private zone: NgZone, private router: Router, private constants: Constants) {
        this.translationsLoaded = false;
    }

    sendAction(command: string, params?: object) {
        const action: IActionMessage = <IActionMessage>{
            action: command,
            params: params
        };

        this.messaging.send(action);
    }

    cleanupSubscription(subscription: Subscription) {
        if (subscription) {
            subscription.unsubscribe();
            subscription = null;
        }
    }

    setLanguageToUse(translate: TranslateService) {
        translate.setDefaultLang(this.constants.DEFAULT_LANGUAGE);
        const selectedLanguage = this.user.selectedLanguage;
        if (selectedLanguage) {
            translate.use(selectedLanguage);
        } else {
            this.user.selectedLanguage = this.constants.DEFAULT_LANGUAGE;
        }
    }

    changeLanguage(translate: TranslateService, languageToUse: string) {
        this.user.selectedLanguage = languageToUse;
        translate.use(languageToUse);
    }

    enableUnderMaintenanceMode() {
        this.zone.run(() => this.router.navigate(['/under-maintenance']));
    }

    disableUnderMaintenanceMode() {
        this.zone.run(() => this.router.navigate(['/home']));
    }

    getTranslatedString(translate: TranslateService, key: string): any {
        return new Promise((resolve, reject) => {
            if (this.translationsLoaded) {
                resolve(translate.instant(key));
            } else {
                translate.get(key).subscribe((translated: string) => {
                    this.translationsLoaded = true;
                    resolve(translated);
                });
            }
        });
    }

    currentLocation() {
        return this.router.url;
    }
}
