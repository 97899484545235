import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctions } from '../common/components/common.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnChanges {

  @Input() innerPage: string;
  isInnerPage: boolean;

  constructor(public translate: TranslateService,
              private common: CommonFunctions) {
      this.common.setLanguageToUse(translate);
      this.isInnerPage = false;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const innerPage: SimpleChange = changes.innerPage;
    if (innerPage && innerPage.currentValue === true) {
      this.isInnerPage = true;
    }
  }

}
