/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.style.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../menu/menu.component.ngfactory";
import * as i4 from "../menu/menu.component";
import * as i5 from "@angular/common";
import * as i6 from "../footer/footer.component.ngfactory";
import * as i7 from "../footer/footer.component";
import * as i8 from "../common/components/common.component";
import * as i9 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "slogan"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 4, "ul", [["class", "app-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "a", [["class", "apple"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "a", [["class", "google"], ["href", "#"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("home.slogan")); _ck(_v, 2, 0, currVal_0); }); }
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "slogan"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("home.coming_soon")); _ck(_v, 2, 0, currVal_0); }); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "section", [["class", "home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "section", [["class", "inner-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-menu", [], null, null, null, i3.View_MenuComponent_0, i3.RenderType_MenuComponent)), i1.ɵdid(4, 114688, null, 0, i4.MenuComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "buttons-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-footer", [], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(11, 638976, null, 0, i7.FooterComponent, [i2.TranslateService, i8.CommonFunctions], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = _co.isGetTheAppVisible; _ck(_v, 7, 0, currVal_0); var currVal_1 = !_co.isGetTheAppVisible; _ck(_v, 9, 0, currVal_1); _ck(_v, 11, 0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i9.HomeComponent, [i2.TranslateService, i8.CommonFunctions], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i9.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
