/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-404.style.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./custom-404.component";
var styles_Custom404Component = [i0.styles];
var RenderType_Custom404Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Custom404Component, data: {} });
export { RenderType_Custom404Component as RenderType_Custom404Component };
export function View_Custom404Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "custom-404"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "alert alert-danger"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops!!! The resource you are looking for cannot be found."]))], null, null); }
export function View_Custom404Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-404", [], null, null, null, View_Custom404Component_0, RenderType_Custom404Component)), i1.ɵdid(1, 49152, null, 0, i2.Custom404Component, [], null, null)], null, null); }
var Custom404ComponentNgFactory = i1.ɵccf("app-custom-404", i2.Custom404Component, View_Custom404Component_Host_0, {}, {}, []);
export { Custom404ComponentNgFactory as Custom404ComponentNgFactory };
