import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertMinsToHrsMins'
})
export class ConvertMinsToHrsMins implements PipeTransform {

    constructor() { }

    transform(minutes: number): string {
        let hour = Math.floor(minutes / 60);
        let minute = minutes % 60;
        if (hour === 0 && minute === 0) {
            minute = 1;
        }
        if (hour > 0 && minute !== 0) {
            hour = hour + 1;
        }
        if (hour > 0) {
            if (hour === 1) {
                return `${hour} hour`;
            } else {
                return `${hour} hours`;
            }
        } else {
            if (minute === 1) {
                return `${minute} minute`;
            } else {
                return `${minute} minutes`;
            }
        }
    }
}
