/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./under-maintenance.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./under-maintenance.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../common/components/common.component";
var styles_UnderMaintenanceComponent = [i0.styles];
var RenderType_UnderMaintenanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnderMaintenanceComponent, data: {} });
export { RenderType_UnderMaintenanceComponent as RenderType_UnderMaintenanceComponent };
export function View_UnderMaintenanceComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_UnderMaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-under-maintenance", [], null, null, null, View_UnderMaintenanceComponent_0, RenderType_UnderMaintenanceComponent)), i1.ɵdid(1, 114688, null, 0, i2.UnderMaintenanceComponent, [i3.TranslateService, i4.CommonFunctions], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnderMaintenanceComponentNgFactory = i1.ɵccf("app-under-maintenance", i2.UnderMaintenanceComponent, View_UnderMaintenanceComponent_Host_0, {}, {}, []);
export { UnderMaintenanceComponentNgFactory as UnderMaintenanceComponentNgFactory };
