import { Injectable } from '@angular/core';

@Injectable()

export class Constants {

    readonly DEFAULT_LANGUAGE: string = 'en';

    constructor() {

    }
}
