import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()

export class ConfigurationService {

    readonly CLIENT_ID: string = 'idealmatch';
    readonly GRANT_TYPE: string = 'password';
    readonly REFRESH_TOKEN_GRANT_TYPE: string = 'refresh_token';
    readonly FACEBOOK_GRANT_TYPE: string = 'facebook_access_token';
    readonly EMAIL_VERIFICATION_GRANT_TYPE: string = 'email_verification';
    readonly PHONE_VERIFICATION_GRANT_TYPE: string = 'phone_verification';

    constructor() {

    }

    get restfulApiBase(): string {
        return environment.apiUrl;
    }

    get facebookAPPID(): string {
        return environment.facebookAppID;
    }

    get recaptchaSiteKey(): string {
        return environment.reCaptchaSiteKey;
    }

    get googleAnalyticsID(): string {
        return environment.googleAnalyticsID;
    }

    get debugMode(): boolean {
        return environment.debugMode;
    }

    get appVersion(): string {
        return environment.appVersion;
    }

}
