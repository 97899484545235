import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Constants } from './constants';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()

export class UserService {

    private authenticated: boolean;
    private access_token: string;
    private refresh_token: string;
    private user_name: string;
    private user_id: number;
    private language: string;

    jwtHelper = new JwtHelperService();

    constructor(private constants: Constants) {
        this.authenticated = false;
        this.access_token = '';
        this.refresh_token = '';
        this.user_name = '';
        this.user_id = 0;
        this.language = '';
    }

    get isAuthenticated(): boolean {
        return this.authenticated || localStorage.getItem('authenticated') === 'true';
    }

    set isAuthenticated(flag: boolean) {
        this.authenticated = flag;
        if (flag) {
            localStorage.setItem('authenticated', 'true');
        } else {
            localStorage.removeItem('authenticated');
        }
    }

    set accessToken(token: string) {
        this.access_token = token;
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return this.access_token || localStorage.getItem('accessToken');
    }

    set refreshToken(token: string) {
        this.refresh_token = token;
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return this.refresh_token || localStorage.getItem('refreshToken');
    }

    set userName(userName: string) {
        this.user_name = userName;
        localStorage.setItem('userName', userName);
    }

    get userName(): string {
        return this.user_name || localStorage.getItem('userName');
    }

    set userid(userid: number) {
        if (userid) {
            this.user_id = userid;
            localStorage.setItem('user_id', userid.toString());
        }
    }

    get userid(): number {
        return this.user_id || JSON.parse(localStorage.getItem('user_id'));
    }

    set selectedLanguage(language: string) {
        this.language = language;
        localStorage.setItem('language', language);
    }

    get selectedLanguage(): string {
        return this.language || localStorage.getItem('language');
    }

}
