export const environment = {
    production: true,
    apiUrl: 'https://qaapi.idealmatch.com',
    facebookAppID: '119288518865418',
    reCaptchaSiteKey: '6LfKGGgUAAAAAAPC8EwyQMWLT2SrxRd5TYzsCIxO',
    googleAnalyticsID: 'UA-123332823-1',
    debugMode: false,
    sentryDNS: 'https://9860d3da3bdf4b3995e6610dad2de0ff@sentry.io/1307237',
    appVersion: '0.1.0',
    sentryEnv: 'prod'
};
