import { Component, OnInit } from '@angular/core';
import { CommonFunctions } from '../common/components/common.component';
import { TranslateService } from '@ngx-translate/core';

declare let analytics: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.template.html',
    styleUrls: ['./home.style.css']
})

export class HomeComponent implements OnInit {

    isGetTheAppVisible: boolean;

    constructor(public translate: TranslateService,
                private common: CommonFunctions) {
        this.common.setLanguageToUse(translate);
        this.isGetTheAppVisible = false;
    }

    ngOnInit() {

    }

}
