import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageBusService } from './core/services/message-bus.service';
import { ConfigurationService } from './core/services/configuration.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { irisAppRouting, irisAppRoutingProviders } from './app.routing';
import { IdlePreloadModule } from 'angular-idle-preload';
import { Custom404Component } from './shared/custom-404/custom-404.component';
import { HomeComponent } from './home/home.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule, MatIconModule, MatButtonModule, MatGridListModule } from '@angular/material';
import { MatSelectModule, MatOptionModule, MatCheckboxModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { CommonFunctions } from './common/components/common.component';
import { Constants } from './core/services/constants';
import { SanitizeHtmlPipe } from './common/pipes/sanitize-html.pipe';
import { RemovePlusSignPipe } from './common/pipes/remove-plus.pipe';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UnderMaintenanceComponent } from './shared/under-maintenance/under-maintenance.component';
import { SharedModule } from './shared/shared/shared.module';
import { UiSwitchModule } from 'ngx-toggle-switch';
// tslint:disable-next-line:max-line-length
import { SelectModule } from 'ng2-select';
import { ConvertMinsToHrsMins } from './common/pipes/convert-minutes-to-hours.pipe';
import { UserService } from './core/services/user.service';
import { MenuComponent } from './menu/menu.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { FooterComponent } from './footer/footer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

// Sentry.init({
//   dsn: environment.sentryDNS,
//   environment: environment.sentryEnv
// });

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() { }
//   handleError(error) {
//     Sentry.captureException(error.originalError || error);
//     throw error;
//   }
// }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    Custom404Component,
    HomeComponent,
    SanitizeHtmlPipe,
    RemovePlusSignPipe,
    UnderMaintenanceComponent,
    MenuComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    FooterComponent
  ],
  imports: [
    IdlePreloadModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    irisAppRouting,
    MatDialogModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatGridListModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    UiSwitchModule,
    SharedModule,
    SelectModule
  ],
  providers: [
    MessageBusService,
    ConfigurationService,
    HttpClient,
    irisAppRoutingProviders,
    CommonFunctions,
    Constants,
    ConvertMinsToHrsMins,
    UserService,
    // {
    //   provide: ErrorHandler,
    //   useClass: SentryErrorHandler
    // }
  ],
  entryComponents: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
