import { Constants } from './constants';
import { JwtHelperService } from '@auth0/angular-jwt';
var UserService = /** @class */ (function () {
    function UserService(constants) {
        this.constants = constants;
        this.jwtHelper = new JwtHelperService();
        this.authenticated = false;
        this.access_token = '';
        this.refresh_token = '';
        this.user_name = '';
        this.user_id = 0;
        this.language = '';
    }
    Object.defineProperty(UserService.prototype, "isAuthenticated", {
        get: function () {
            return this.authenticated || localStorage.getItem('authenticated') === 'true';
        },
        set: function (flag) {
            this.authenticated = flag;
            if (flag) {
                localStorage.setItem('authenticated', 'true');
            }
            else {
                localStorage.removeItem('authenticated');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "accessToken", {
        get: function () {
            return this.access_token || localStorage.getItem('accessToken');
        },
        set: function (token) {
            this.access_token = token;
            localStorage.setItem('accessToken', token);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "refreshToken", {
        get: function () {
            return this.refresh_token || localStorage.getItem('refreshToken');
        },
        set: function (token) {
            this.refresh_token = token;
            localStorage.setItem('refreshToken', token);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "userName", {
        get: function () {
            return this.user_name || localStorage.getItem('userName');
        },
        set: function (userName) {
            this.user_name = userName;
            localStorage.setItem('userName', userName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "userid", {
        get: function () {
            return this.user_id || JSON.parse(localStorage.getItem('user_id'));
        },
        set: function (userid) {
            if (userid) {
                this.user_id = userid;
                localStorage.setItem('user_id', userid.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "selectedLanguage", {
        get: function () {
            return this.language || localStorage.getItem('language');
        },
        set: function (language) {
            this.language = language;
            localStorage.setItem('language', language);
        },
        enumerable: true,
        configurable: true
    });
    return UserService;
}());
export { UserService };
