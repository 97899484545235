<section class="home">
    <div class="overlay"></div>
    <section class="inner-container">
        <app-menu></app-menu>
        <div class="buttons-container">
            <ng-container *ngIf="isGetTheAppVisible">
                <div class="slogan">{{ 'home.slogan' | translate }}</div>
                <ul class="app-links">
                    <li>
                        <a href="#" class="apple"></a>
                    </li>
                    <li>
                        <a href="#" class="google"></a>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="!isGetTheAppVisible">
                <div class="slogan">{{ 'home.coming_soon' | translate }}</div>
            </ng-container>
        </div>
        <app-footer></app-footer>
    </section>
</section>
