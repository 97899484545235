import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctions } from '../../common/components/common.component';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.css']
})
export class UnderMaintenanceComponent implements OnInit {

  constructor(public translate: TranslateService, private common: CommonFunctions) {
    this.common.setLanguageToUse(translate);
  }

  ngOnInit() {
  }

}
