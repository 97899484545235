import { environment } from '../../../environments/environment';
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService() {
        this.CLIENT_ID = 'idealmatch';
        this.GRANT_TYPE = 'password';
        this.REFRESH_TOKEN_GRANT_TYPE = 'refresh_token';
        this.FACEBOOK_GRANT_TYPE = 'facebook_access_token';
        this.EMAIL_VERIFICATION_GRANT_TYPE = 'email_verification';
        this.PHONE_VERIFICATION_GRANT_TYPE = 'phone_verification';
    }
    Object.defineProperty(ConfigurationService.prototype, "restfulApiBase", {
        get: function () {
            return environment.apiUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "facebookAPPID", {
        get: function () {
            return environment.facebookAppID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "recaptchaSiteKey", {
        get: function () {
            return environment.reCaptchaSiteKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "googleAnalyticsID", {
        get: function () {
            return environment.googleAnalyticsID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "debugMode", {
        get: function () {
            return environment.debugMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "appVersion", {
        get: function () {
            return environment.appVersion;
        },
        enumerable: true,
        configurable: true
    });
    return ConfigurationService;
}());
export { ConfigurationService };
