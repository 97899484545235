import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { IActionMessage } from './core/interfaces/action-message.interface';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from './core/services/user.service';
import { ConfigurationService } from './core/services/configuration.service';
import { CommonFunctions } from './common/components/common.component';

declare let analytics: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Iris';

  constructor(private router: Router,
    private user: UserService,
    private config: ConfigurationService,
    private common: CommonFunctions,
    private zone: NgZone) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const user_id = this.user.userid;
        let urlAfterRedirects = '';
        if (event.urlAfterRedirects && event.urlAfterRedirects.indexOf('?') > 0) {
          urlAfterRedirects = event.urlAfterRedirects.substring(0, event.urlAfterRedirects.indexOf('?'));
        } else {
          urlAfterRedirects = event.urlAfterRedirects;
        }
        if (user_id) {
          analytics.page(urlAfterRedirects, { 'user_id': user_id });
        } else {
          analytics.page(urlAfterRedirects);
        }
      }
    });

  }

  ngOnInit() {

  }
}
